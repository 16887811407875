// LanguageDropdown.js
import React, { useContext } from 'react';
import Select from 'react-select';
import LanguageContext from '../../LanguageContext'; // Context'inize göre düzenleyin

const LanguageDropdown2 = () => {
  const { language, changeLanguage } = useContext(LanguageContext);

  const options = [
    { value: 'en', flag: '/images/bg/eng.png' },
    { value: 'tr', flag: '/images/bg/trk.png' },
    { value: 'ru', flag: '/images/bg/rus.png' }
  ];

  const formatOptionLabel = ({ label, flag }) => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <img src={flag} alt="" style={{ marginTop:2, width: 40 }} />
      <span>{label}</span>
    </div>
  );
  const DropdownComponents = {
    DropdownIndicator: null, // Dropdown okunu kaldır
    ClearIndicator: null, // Temizleme ikonunu kaldır
    // Diğer özelleştirilmiş component'lar buraya eklenebilir
  };

  const customStyles = {
    control: (provided) => ({
        ...provided,
        backgroundColor: 'transparent', // Kontrol bölümünün arka planını şeffaf yapar
        border: 'none', // Kenarlığı kaldırır
        boxShadow: 'none', // Gölge kaldırılır
        width: "40px",

      }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: '0f0f11', // Menü bölümünün arka plan rengi
      borderColor: '#333',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.15)' // Menü için gölge efekti
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? 'black' : '#0f0f11', // Seçeneklerin arka plan rengi
      color: '#acacac',
      '&:hover': {
        backgroundColor: 'black', // Üzerine gelindiğinde seçeneklerin arka plan rengi
        color: '#acacac'
      }
    }),
    singleValue: (provided) => ({
      ...provided,
      color: '#acacac', // Seçili değerin rengi
    })
  };

  const handleChange = (selectedOption) => {
    changeLanguage(selectedOption.value);
  };

  return (
    <Select
      value={options.find(option => option.value === language)}
      onChange={handleChange}
      options={options}
      formatOptionLabel={formatOptionLabel}
      styles={customStyles} // Burada özel stilleri uyguluyoruz
      components={DropdownComponents} // Özelleştirilmiş component'ları burada kullan

    />
  );
};

export default LanguageDropdown2;
