import React, { useState, useEffect,useContext } from 'react';
import LanguageContext from "../../LanguageContext";
import { RiCompassDiscoverLine } from "react-icons/ri";
import backgroundImage from '../../assets/images/bg/blueback.png'; // Yolu doğru belirttiğinizden emin olun
import {
  FiMapPin
} from "react-icons/fi";


const BackgroundVideo = ({veriheight,translations}) => {
 
 
  
  return (
    <div style={{position:"relative",height:`auto`}} className='page-wrapper'>
<div className='tacirmainorta'>

</div>

    <div className='tacirortayazı'>
    <div className='tacirlerortaici'>
    <h1 style={{textAlign:"center"}} className='newakefont tacirortah1content'>{translations.basbasname}</h1>
    <span className='newakefont tacirortaspancontent'>{translations.basbascontent}</span>
    </div>
    <div className='tacirortabuttonstyle'>
    <a href="https://www.google.com/maps?rlz=1C1OKWM_trTR1085TR1085&gs_lcrp=EgZjaHJvbWUyBggAEEUYOdIBCDIzNzVqMGoxqAIAsAIA&um=1&ie=UTF-8&fb=1&gl=tr&sa=X&geocode=KWHpSF9yV7UUMSZIwDCFhf0W&daddr=Had%C4%B1mk%C3%B6y,+29.+May%C4%B1s+Cd.+No:40,+34555+Arnavutk%C3%B6y/%C4%B0stanbul" 
              target="_blank" rel="noopener noreferrer" className="btnn newakefont">
    <button style={{width:"50px", color:"red"}}  className="lizo">
          <div style={{color:"red"}} ><FiMapPin />
          </div>
        </button>
        </a>
        <a href="tel:+905332004233" className="btnn newakefont">
        <button style={{width:"250px",marginBottom:"50px"}}  className="divra">
          <span>{translations.navfive}</span>
          <div className='denemephone'></div>  
        </button>
        </a>
      </div>


    </div>
    </div>
  );
};

export default BackgroundVideo;