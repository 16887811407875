import React, { useEffect, useState,useRef,useContext } from 'react';
import { Link } from 'react-router-dom';
import SEO from '../common/SEO';
import HeaderTwo from '../common/header/HeaderTwo';
import HeaderTopBar from '../common/header/HeaderTopBar';
import HeaderOne from '../common/header/HeaderOne';

import CenterHeader from '../common/header/CenteredHeader';

import Secondtacir from '../elements/worldmaps/Secondtacir.js';
import ThirdTacir from '../elements/worldmaps/ThirdTacir.js';
import FourTacir from '../elements/worldmaps/FourTacir.js';


import BackgroundVideo from '../elements/worldmaps/pagetacir.js';
import SecondServices from '../elements/worldmaps/secondservices.js';

import 'react-tooltip/dist/react-tooltip.css';



import LanguageContext from "../LanguageContext";


import FooterFour from '../common/footer/FooterFour';
import FooterTwo from '../common/footer/FooterTwo';



function Finance() {
  const [windowHeight, setWindowHeight] = useState(800);
  const [increasestart, setincreasestart] = useState(0);
  const [decreasestart, setdecreasestart] = useState(0);
  const [decreasestart2, setdecreasestart2] = useState(0);
  const [increasestartBOTTOM, setincreasestartBOTTOM] = useState(0);


  const { translations } = useContext(LanguageContext);



  return (
    <>
      <SEO title="Anasayfa" />
      <main className="page-wrapper">
        <div>
        <HeaderTopBar></HeaderTopBar>

          <HeaderOne btnStyle="btn-small btn-icon" HeaderSTyle="rn-header header-default header-not-transparent header-default " Loco="main"/>
        </div>
        <div className='tacirmainortatrans'>
        <div id="mainpage"><BackgroundVideo  translations={translations}/></div>
        <div id="twotacirsection"> <Secondtacir  translations={translations} /></div>
        <div id="fourtacissection"><FourTacir  translations={translations}/></div>
          </div>
      </main>
    </>
  );
}

export default Finance;

