import { red } from "@mui/material/colors";
import React from "react";
import {
  FiFacebook,
  FiTwitter,
  FiInstagram,
  FiLinkedin,
  FiChevronRight,
  FiMapPin,
  FiPhone,
} from "react-icons/fi";
import { Link } from "react-router-dom";

const HeaderTopBar = () => {
  return (
    <div className="header-top-bar">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-4 col-md-12 col-12">
            <div className="header-left"></div>
          </div>
          <div className="col-lg-8 col-md-12 col-12">
            <div className="header-right">
              <div className="address-content">
              <div className="btn-cont" style={{lineHeight:"initial",height:"initial",fontSize:"16px",color:"red"}}>
              <a href="https://www.google.com/maps?rlz=1C1OKWM_trTR1085TR1085&gs_lcrp=EgZjaHJvbWUyBggAEEUYOdIBCDIzNzVqMGoxqAIAsAIA&um=1&ie=UTF-8&fb=1&gl=tr&sa=X&geocode=KWHpSF9yV7UUMSZIwDCFhf0W&daddr=Had%C4%B1mk%C3%B6y,+29.+May%C4%B1s+Cd.+No:40,+34555+Arnavutk%C3%B6y/%C4%B0stanbul" 
              target="_blank" rel="noopener noreferrer" style={{color:"red"}}>
              <FiMapPin />
              </a>
    
              <a href="https://www.google.com/maps?rlz=1C1OKWM_trTR1085TR1085&gs_lcrp=EgZjaHJvbWUyBggAEEUYOdIBCDIzNzVqMGoxqAIAsAIA&um=1&ie=UTF-8&fb=1&gl=tr&sa=X&geocode=KWHpSF9yV7UUMSZIwDCFhf0W&daddr=Had%C4%B1mk%C3%B6y,+29.+May%C4%B1s+Cd.+No:40,+34555+Arnavutk%C3%B6y/%C4%B0stanbul" 
              target="_blank" rel="noopener noreferrer" className="btnn newakefont">
              Arnavutköy / İSTANBUL
                <span className="line-2"></span>
                <span className="line-4"></span>
              </a>
            </div>

            <div className="btn-cont" style={{lineHeight:"initial",height:"initial",fontSize:"16px", }}>
              <FiPhone />

            <a href="tel:+905332004233" 
            target="_blank" rel="noopener noreferrer" className="btnn newakefont">
            +90  533 200 42 33
              <span className="line-2"></span>
              <span className="line-4"></span>
            </a>
          </div>
          
              </div>
              <div className="social-icon-wrapper">
                <ul className="social-icon social-default icon-naked">
                  <li>
                    <Link to="twitter.com">
                      <FiFacebook />
                    </Link>
                  </li>
                  <li>
                    <Link to="twitter.com">
                      <FiTwitter />
                    </Link>
                  </li>
                  <li>
                    <Link to="instagram.com">
                      <FiInstagram />
                    </Link>
                  </li>
                  <li>
                    <Link to="linkdin.com">
                      <FiLinkedin />
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderTopBar;
