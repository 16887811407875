import React, { useState, useEffect, useContext } from "react";
import { FaMapMarkerAlt, FaPhone, FaEnvelope } from "react-icons/fa";
import LanguageContext from "LanguageContext";
import { TbRouteAltRight } from "react-icons/tb";

const FourTacir = ({ veriheight, translations }) => {
  return (
    <div
      style={{ position: "relative", height: `auto`, marginTop: "-10px" }}
      className="page-wrapper"
    >
      <div>
        <div className="tacirmainorta"></div>
        <div className="tacirortayazı">
          <div className="tacirlerortaother">
            <h1 className="newakefont tacirortah1contentlittle">
              {translations.navfive}
            </h1>
            <span className="newakefont tacirortaspancontent">
              {translations.tacirleriletisim}
            </span>
          </div>
          <form class="tacirlercontact-form newakefont">
            <div class="tacirlerform-group">
              <input
                type="text"
                id="name"
                name="name"
                placeholder={translations.formname}
              />
            </div>
            <div class="tacirlerform-group">
              <input
                type="email"
                id="email"
                name="email"
                placeholder={translations.formmail}
              />
            </div>
            <div class="tacirlerform-group">
              <input
                type="tel"
                id="phone"
                name="phone"
                placeholder={translations.formtel}
              />
            </div>
            <div class="tacirlerform-group">
              <input
                type="text"
                id="subject"
                name="subject"
                placeholder={translations.formkonu}
              />
            </div>
            <div class="tacirlerform-group">
              <textarea
                id="message"
                name="message"
                placeholder={translations.formmesaj}
              ></textarea>
            </div>
            <div className="basdivstyle">
              <button
                type="submit"
                style={{ width: "100%", height: "60px", fontSize: "25px" }}
                className="divra"
              >
                <span>{translations.TACOFORM}</span>
              </button>

              <div className="contact-info">
                <div className="info-item" style={{ display: "grid" }}>
                  <div
                    style={{
                      display: "flex",
                      justifyItems: "center",
                      alignItems: "center",
                    }}
                  >
                    <FaMapMarkerAlt />
                    <span style={{ marginLeft: "5px" }}>
                      {translations.contacttoad}{" "}
                    </span>
                  </div>
                  <span>
                    Hadımköy Hastane Mahallesi, 29. Mayıs Cd. No:40, 34555
                    Arnavutköy/İstanbul
                  </span>
                </div>
                <div className="info-item" style={{ display: "grid" }}>
                  <div
                    style={{
                      display: "flex",
                      justifyItems: "center",
                      alignItems: "center",
                    }}
                  >
                    <FaPhone />
                    <span style={{ marginLeft: "5px" }}>
                      {translations.contacttoadtel}{" "}
                    </span>
                  </div>
                  <a href="tel:+902127710790">
                    <span>+90 0212 771 07 90</span>
                  </a>
                  <a href="tel:+902127710795">
                    <span>+90 0212 771 07 95</span>
                  </a>
                  <a href="tel:+902127710799">
                    <span>+90 0212 771 07 99</span>
                  </a>
                </div>
                <div className="info-item" style={{ display: "grid" }}>
                  <div
                    style={{
                      display: "flex",
                      justifyItems: "center",
                      alignItems: "center",
                    }}
                  >
                    <FaEnvelope />
                    <span style={{ marginLeft: "10px" }}>
                      {translations.contacttoadmail}{" "}
                    </span>
                  </div>
                  <a
                    href="mailto:info@tacirlerantrepo.com.tr"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    info@tacirlerantrepo.com.tr
                  </a>
                  <a
                    href="mailto:depo@tacirlerantrepo.com.tr"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    depo@tacirlerantrepo.com.tr
                  </a>
                  <a
                    href="mailto:antrepo@tacirlerantrepo.com.tr"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    antrepo@tacirlerantrepo.com.tr
                  </a>
                  <a
                    href="mailto:antrepo@tacirlerantrepo.com.tr"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    muhasebe@tacirlerantrepo.com.tr
                  </a>
                </div>
              </div>
            </div>
          </form>

          <div
            className="rn-contact-address mt_dec--30"
            style={{ width: "95%", margin: "0 auto" }}
          >
            <div>
              <h1 className="newakefont gord">{translations.contacttitle}</h1>
            </div>
            <div>
              <div style={{ position: "relative" }}>
                <div className="absolutegooglemap">
                  <div className="absolutegooglemapinner newakefont">
                    <div className="absolutegooglemapgridinner">
                      <span className="absolutegoogletitle">
                        Tacirler Antrepo
                      </span>
                      <span className="absolutegoogledesc ">
                        Hadımköy Hastane Mahallesi, 29. Mayıs Cd. No:40, 34555
                        Arnavutköy/İstanbul
                      </span>
                    </div>
                    <div className="holdergoogleicon">
                      <a
                        href="https://www.google.com/maps?rlz=1C1OKWM_trTR1085TR1085&gs_lcrp=EgZjaHJvbWUyBggAEEUYOdIBCDIzNzVqMGoxqAIAsAIA&um=1&ie=UTF-8&fb=1&gl=tr&sa=X&geocode=KWHpSF9yV7UUMSZIwDCFhf0W&daddr=Had%C4%B1mk%C3%B6y,+29.+May%C4%B1s+Cd.+No:40,+34555+Arnavutk%C3%B6y/%C4%B0stanbul"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {" "}
                        <div className="absolutegoogleicon">
                          <TbRouteAltRight />
                        </div>
                      </a>
                      <a
                        href="https://www.google.com/maps?rlz=1C1OKWM_trTR1085TR1085&gs_lcrp=EgZjaHJvbWUyBggAEEUYOdIBCDIzNzVqMGoxqAIAsAIA&um=1&ie=UTF-8&fb=1&gl=tr&sa=X&geocode=KWHpSF9yV7UUMSZIwDCFhf0W&daddr=Had%C4%B1mk%C3%B6y,+29.+May%C4%B1s+Cd.+No:40,+34555+Arnavutk%C3%B6y/%C4%B0stanbul"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {" "}
                        <div style={{ marginTop: "-10px" }}>
                          <span className="absolutegoogleunderline newakefont">
                            Yol Tarifi
                          </span>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12097.433213460943!2d-74.0062269!3d40.7101282!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb89d1fe6bc499443!2sDowntown+Conference+Center!5e0!3m2!1smk!2sbg!https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3004.420614347158!2d28.613577783107747!3d41.147172431867446!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14b557725f48e961%3A0x16fd858530c04826!2sTACIRLER%20ANTREPO!5e0!3m2!1str!2sus!4v1699108604640!5m2!1str!2sus"
                  width="100%"
                  height="550px"
                  style={{
                    border: "2px solid rgba(17, 88, 250, 0.4)",
                    borderRadius: "0px",
                  }}
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </div>
            <div
              style={{
                position: "absolute",
                bottom: "0",
                background: "rgba(0,0,0,0.7)",
                height: "auto",
                width: "100%",
                left: "0",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <h1
                style={{
                  fontSize: "20px",
                  textAlign: "center",
                  margin: "auto",
                  color: "rgba(255,255,255,255)",
                  padding: "5px",
                }}
                className="newakefont"
              >
                <span style={{ color: "rgba(255,255,255,255)" }}>
                  {translations.fottitone}{" "}
                </span>
                <span style={{ color: "rgba(38, 121, 255,0.8)" }}>
                  {translations.fottittwo}{" "}
                </span>
                <span style={{ color: "rgba(255,255,255,255)" }}>
                  {" "}
                  {translations.fottitthree}.
                </span>
              </h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FourTacir;
