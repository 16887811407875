import React, { useContext, useState, useEffect, useRef } from "react";
import { useLocation } from 'react-router-dom';
import LanguageContext from "../../LanguageContext.js";
import LanguageDropdown from "./LanguageSelect.js";
import { FiArrowRight } from "react-icons/fi";

const Nav = () => {
  const { language, changeLanguage, translations } = useContext(LanguageContext);
  const [activeLink, setActiveLink] = useState('');
  const location = useLocation();
  const sectionRefs = useRef({});

  const sections = {
    '#mainpage': '#mainpage',
    '#services': '#twotacirsection',
    '#globalcustomernetwork': '#thirdtacirsection',
    '#contact': '#fourtacissection'
  };

  useEffect(() => {
    // Bölümlerin referanslarını önceden al
    Object.keys(sections).forEach(id => {
      sectionRefs.current[id] = document.querySelector(sections[id]);
    });

    const onScroll = () => {
      let currentSection = activeLink;

      Object.keys(sections).forEach(id => {
        const section = sectionRefs.current[id];
        if (section) {
          const sectionTop = section.getBoundingClientRect().top;
          const sectionHeight = section.offsetHeight;
          const withinView = sectionTop <= window.innerHeight / 2 && sectionTop >= -sectionHeight / 2;

          if (withinView) {
            currentSection = id;
          }
        }
      });

      if (currentSection !== activeLink) {
        setActiveLink(currentSection);
      }
    };

    const throttledScroll = throttle(onScroll, 100); // Scroll olayını sınırla
    window.addEventListener('scroll', throttledScroll);

    return () => window.removeEventListener('scroll', throttledScroll);
  }, [sections, activeLink]);

  const handleClick = () => {
    window.location.href = '/getoffer';
  };

  const handleLanguageChange = (event) => {
    changeLanguage(event.target.value);
  };
  return (
    <ul className="mainmenu">
            <li className={`has-dropdown newakefont getbigger ${activeLink === '#mainpage' ? 'cobbo' : ''}`}>
        <ul style={{ margin: 0 }} className="navwreck">
        <a href="#mainpage">{translations.navone}</a>
        </ul>
      </li>

      <li className={`has-dropdown newakefont getbigger ${activeLink === '#services' ? 'cobbo' : ''}`}>
        <ul style={{ margin: 0 }} className="navwreck">
        <a href="#twotacirsection">{translations.navthree}</a>
        </ul>
      </li>

      <li className={`has-dropdown newakefont getbigger ${activeLink === '#contact' ? 'cobbo' : ''}`}>
        <ul style={{ margin: 0 }} className="navwreck">
        <a href="#fourtacissection">{translations.navfive}</a>
        </ul>
      </li>
   
   
      <li className="has-droupdown newakefont">
        <div className="btn-cont getbigger" style={{ marginTop: "-6px" }}>
          <LanguageDropdown
            language={language}
            changeLanguage={changeLanguage}
          />
        </div>
      </li>
      <li></li>
      <div className="vikart" style={{position: "absolute", bottom: "30px", left: "0px", width: "100%"}}>
        <button onClick={handleClick} className="divra">
          <span>{translations.navsix}</span>
        </button>
      </div>
    </ul>
  );
};

function throttle(fn, wait) {
  let lastTime = 0;
  return function (...args) {
    const now = new Date().getTime();
    if (now - lastTime < wait) return;
    lastTime = now;
    return fn(...args);
  };
}
export default Nav;

