import React, { useState, useEffect, useContext } from "react";
import { RiCompassDiscoverLine } from "react-icons/ri";
import backgroundImage from "../../assets/images/bg/blueback.png"; // Yolu doğru belirttiğinizden emin olun
import {
  FiFacebook,
  FiTwitter,
  FiInstagram,
  FiLinkedin,
  FiChevronRight,
  FiMapPin,
  FiPhone,
} from "react-icons/fi";
import Slo  from "./slider2";
import { LiaPhoneSolid } from "react-icons/lia";
import { PiPhoneThin } from "react-icons/pi";
import palet from "../../assets/images/bg/paleticon.png"
import ellec  from "../../assets/images/bg/ellecicon.png"
import serbestdepo  from "../../assets/images/bg/serbestdepo.png"
import antrepo  from "../../assets/images/bg/antrepo.png"
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';



const Secondtacir = ({ veriheight,translations }) => {
  const [text, setText] = useState('');
  const [texttitle, setTextTitle] = useState('');
  const [detailData, setDetailData] = useState([]);
  const [detailDataSerbest, setDetailDataSerbest] = useState([]);
  const [detailDataMain, setdetailDataMain] = useState([]);
  const [showDetailTab, setShowDetailTab] = useState(true);



  const [activeButton, setActiveButton] = useState(3); // Başlangıçta 4 olarak ayarlayın

  useEffect(() => {
    // Sayfa ilk yüklendiğinde, Serbest Depo'nun içeriğini ve başlığını ayarlayın
    setText(translations.tacirlerservicetitlecontentthree);
    setTextTitle(translations.tacirlerservicetitlethree);
    setdetailDataMain(translations.Detaill);

  }, [translations]);

  const handleClick = (content, title, buttonId) => {
    setText(content);
    setTextTitle(title);
    setActiveButton(buttonId);
    if(buttonId===3)
    {
      setdetailDataMain(translations.Detaill);
    }
    else if(buttonId===4)
    {
      setdetailDataMain(translations.DetailSerbest);

    }
    else
    {}
    // Elleçleme ve Paletleme için Detail tab'ını gizle
    setShowDetailTab(buttonId === 3 || buttonId === 4);
  };


  const getButtonClass = (buttonId) => {
    return `littedivra ${activeButton === buttonId ? 'divra' : ''}`;
  };


    function renderDetailList(detailDataMain) {
      return (
        <div class="list barrdxxx">
        <ul>
          {detailDataMain.map((item, index) => (
            <li key={index}><span className="newakefont">{item}</span></li>
          ))}
        </ul>
        </div>
  
      );
  }

  
  return (
    <div
      style={{ position: "relative", height: `auto` ,marginTop:"-15px" }}
      className="page-wrapper"
    >
      <div>
        <div className="tacirmainorta"></div>
        <div className="tacirortayazı">
          <div className="tacirlerortaother " >
            <h1 className="titwakefont tacirortah1contentlittle">{texttitle}</h1>
            <div className="row paco bordernew">
  <div className="col-md-6">
  <Slo></Slo>
  </div>
  <div className="col-md-6">
  <Tabs className="borderradtab newakefont">
    <TabList>
      {showDetailTab && <Tab>{translations.Detail}</Tab>}
      <Tab>{translations.Info}</Tab>
    </TabList>

    {showDetailTab && (
      <TabPanel>
        {renderDetailList(detailDataMain)}
      </TabPanel>
    )}

    <TabPanel>
      <span className="newakefont tacirortaspancontentlotto taco">
        {text}
      </span>
    </TabPanel>
  </Tabs>
</div>
          </div>
          </div>
          <div className="tacirortabuttonstylestr">
        
          <button style={{ width: "auto",padding:"20px",marginRight:"5px"}} className={getButtonClass(3)} onClick={() => handleClick(translations.tacirlerservicetitlecontentthree, translations.tacirlerservicetitlethree,3)}>
            <img src={antrepo} style={{width:"30px", marginRight:"7px"}}></img>
            <span>{translations.Num1}</span>
            </button>
            <button style={{ width: "auto",padding:"20px",marginRight:"5px" }} className={getButtonClass(4)} onClick={() => handleClick(translations.tacirlerservicetitlecontentfour, translations.tacirlerservicetitlefour,4)}>
            <img src={serbestdepo} style={{width:"30px", marginRight:"7px"}}></img>  
            <span>{translations.Num2}</span>
            </button>
            <button style={{ width: "auto",padding:"20px",marginRight:"5px" }} className={getButtonClass(1)} onClick={() => handleClick(translations.tacirlerservicetitlecontent, translations.tacirlerservicetitleone,1)}>
            <img src={palet} style={{width:"30px", marginRight:"7px"}}></img>
            <span>{translations.Num3}</span>
            </button>
            <button style={{ width: "auto",padding:"20px",marginRight:"5px" }} className={getButtonClass(2)} onClick={() => handleClick(translations.tacirlerservicetitlecontenttwo, translations.tacirlerservicetitletwo,2)}>
            <img src={ellec} style={{width:"30px", marginRight:"7px"}}></img>
            <span>{translations.Num4}</span>
            </button>
         
        
          </div>

   
        </div>
      </div>
    </div>
  );
};

export default Secondtacir;
